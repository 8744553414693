var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":"#2e2f3c"}},[_c('v-toolbar',{attrs:{"dark":"","transparent":"","color":"#2e2f3c","elevation":"0"}},[(_vm.isPicture)?_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.expand()}}},[_c('v-icon',{staticClass:"mt-6",attrs:{"size":"35"}},[_vm._v("mdi-arrow-expand")])],1):_vm._e(),_c('v-toolbar-title'),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_c('v-icon',{staticClass:"mt-4",attrs:{"size":"35"}},[_vm._v("mdi-close")])],1)],1),_c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp
        ? 'py-15 px-15'
        : _vm.$vuetify.breakpoint.xs
        ? 'py-6 px-0'
        : 'py-6 px-3',attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"align":"center","cols":"12","md":"8"}},[_c('v-carousel',{style:(_vm.$vuetify.breakpoint.xs ? '' : 'max-width: 90%'),attrs:{"height":"auto","hide-delimiter-background":""},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.project.Bilderreihenfolge),function(item,i){return _c('div',{key:i},[(item.Typ == 'Bild')?_c('v-carousel-item',{attrs:{"src":`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${_vm.project.path.replaceAll(
                ' ',
                '+'
              )}/${item.Pfad.replaceAll(' ', '+')}`}}):_vm._e(),(item.Typ == 'Video')?_c('v-carousel-item',[_c('video-player',{staticClass:"mb-12",attrs:{"src":"https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/landingpage-video.mp4","poster":"https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/3.jpg","options":{
                  fluid: true,
                  muted: false,
                  language: 'de',
                  playbackRates: [1.0],
                  sources: [
                    {
                      type: 'video/mp4',
                      src: 'https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/landingpage-video.mp4',
                    },
                  ],
                }}})],1):_vm._e(),(item.Typ == 'YouTube')?_c('v-carousel-item',[_c('youtube',{ref:"youtube",refInFor:true,staticClass:"mb-12",attrs:{"video-id":item.ID,"fitParent":true,"resize":true}})],1):_vm._e(),(item.Typ == 'TikTok')?_c('v-carousel-item',[_c('iframe',{staticClass:"mb-12",staticStyle:{"border-radius":"11px","margin-top":"-1px"},attrs:{"height":"756px","width":"323","src":`https://www.tiktok.com/embed/v2/${item.ID}`,"frameborder":"0"}})]):_vm._e()],1)}),0)],1),_c('v-col',{staticClass:"order-first order-md-last",attrs:{"cols":"12","md":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'details-mobile' : 'details pl-10'},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'start',"cols":"12","sm":"4","md":"12"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.project.Kunde)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.project.Typ)+" ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'start',"cols":"12","sm":"4","md":"12"}},[_c('h3',[_vm._v("Pro­duk­tions­dauer")]),_c('p',[_vm._v(" "+_vm._s(_vm.project.Produktionsdauer)+" ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'start',"cols":"12","sm":"4","md":"12"}},[_c('h3',[_vm._v("Auf­gaben­be­reich")]),_c('p',[_vm._v(" "+_vm._s(_vm.project.Aufgabenbereich)+" ")])])],1)],1)])],1)],1),(_vm.dialog_view_item.Pfad)?_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"#2e2f3c"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-img',{attrs:{"src":`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${_vm.project.path.replaceAll(
          ' ',
          '+'
        )}/${_vm.dialog_view_item.Pfad.replaceAll(' ', '+')}`,"contain":"","height":"100vh"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }