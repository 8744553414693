import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import router from "./router";
import VueVideoPlayer from "vue-video-player";
import VueYoutube from "vue-youtube";

// require videojs style
Vue.use(VueYoutube);
Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);

Vue.config.productionTip = false;
Vue.use(VueRouter);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
