<template>
  <div class="menubar-spacing">
    <center>
      <h2
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'font-size: 30pt; line-height: 1'
            : 'line-height: 1'
        "
      >
        PORTFOLIO
      </h2>
      <h3 class="pt-1 category-btn">
        <a
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 0.8em;' : ''"
          :class="
            selectedCategory == 0 ? 'category-btn-selected' : 'category-btn'
          "
          @click="selectCategory(0)"
          >Video</a
        >
        |
        <a
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 0.8em;' : ''"
          :class="
            selectedCategory == 1 ? 'category-btn-selected' : 'category-btn'
          "
          @click="selectCategory(1)"
          >Foto</a
        >
        |
        <a
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 0.8em;' : ''"
          :class="
            selectedCategory == 2 ? 'category-btn-selected' : 'category-btn'
          "
          @click="selectCategory(2)"
          >Social Media</a
        >
      </h3>
      <div
        :class="
          'my-9 d-inline-block ' + ($vuetify.breakpoint.xs ? 'px-5' : 'px-15')
        "
        :style="$vuetify.breakpoint.xs ? '' : 'border-left: 3px solid white'"
      >
        <span v-if="selectedCategory == 0">
          <b
            >"Fotografie ermöglicht uns nicht nur, Erinnerungen <br />
            festzuhalten, sondern auch, welche zu kreieren."</b
          ><br />
          JAMES WAYNER
        </span>
        <span v-if="selectedCategory == 1">
          <b
            >"Wenn ein Film Erfolg hat, ist er ein Geschäft. <br />
            Wenn er keinen Erfolg hat, ist er Kunst."</b
          ><br />
          CARLO PONTI
        </span>
        <span v-if="selectedCategory == 2">
          <b
            >"Social Media geht nicht wieder weg; ist keine Modeerscheinung.
            <br />
            Seien Sie dort, wo Ihre Kunden sind: in den sozialen Medien."</b
          ><br />
          LORI RUFF
        </span>
      </div>
    </center>
    <v-container>
      <v-row class="pb-10">
        <v-col
          @click="openDialog(project)"
          cols="12"
          sm="6"
          md="4"
          v-for="(project, index) in filterPortfolio()"
          :key="index"
          style="position: relative; cursor: pointer"
          align="center"
          class="px-4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${project.path.replaceAll(
                  ' ',
                  '+'
                )}/${project.Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%; right: 10%;`"
                align="left"
              >
                <b class="mb-0">{{ project.Kunde.toUpperCase() }}</b>
                <p class="mb-0">{{ project.Typ.toUpperCase() }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      width="90%"
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
    >
      <ProjectView :project="currentProject" @close-dialog="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { loadJsonPortfolio, loadJsonProject } from "./aws.js";
import ProjectView from "@/components/ProjectView.vue";
export default {
  data() {
    return {
      projects: {
        Foto: [],
        Video: [],
        "Social Media": [],
      },
      selectedCategory: 0,
      dialog: false,
      currentProject: null,
    };
  },
  mounted() {
    loadJsonPortfolio()
      .then((res) => {
        // iterate categories "foto, video and social media"
        Object.keys(res.data).forEach((category) => {
          // iterate over projects within category
          res.data[category].forEach((project) => {
            loadJsonProject(project)
              .then((response) => {
                // add project data to project list
                let project_data = response.data;
                project_data.path = project;
                this.projects[category].push(project_data);
              })
              .catch((err) => console.log(err));
          });
        });
      })
      .catch((err) => console.log(err));
  },
  methods: {
    selectCategory(category_id) {
      this.selectedCategory = category_id;
    },
    filterPortfolio() {
      let categoryName = {
        0: "Video",
        1: "Foto",
        2: "Social Media",
      }[this.selectedCategory];
      return this.projects[categoryName];
    },
    openDialog(project) {
      this.dialog = true;
      this.currentProject = project;
    },
  },
  components: { ProjectView },
};
</script>

<style>
.category-btn-selected {
  padding: 0px 10px;
  text-transform: uppercase;
  font-weight: 400;
}
.category-btn {
  padding: 0px 10px;
  text-transform: uppercase;
  font-weight: 200;
}
</style>
