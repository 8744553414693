<template>
  <div class="menubar-spacing">
    <center>
      <h2>HAPPY CUSTOMERS</h2>
    </center>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="4"
          v-for="(customer, index) in customers"
          :key="index"
          style="text-align: -webkit-center"
        >
          <v-img
            width="300px"
            :src="
              'https://11sevendome-website.s3.eu-central-1.amazonaws.com/customers/' +
              customer
            "
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { loadJsonCustomers } from "./aws.js";

export default {
  data() {
    return {
      customers: [],
    };
  },
  mounted() {
    loadJsonCustomers()
      .then((res) => {
        this.customers = res.data;
      })
      .catch((err) => console.log(err));
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
