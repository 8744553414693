<template>
  <v-card color="#2e2f3c">
    <v-toolbar dark transparent color="#2e2f3c" elevation="0">
      <v-btn v-if="isPicture" icon dark class="ml-1" @click="expand()">
        <v-icon size="35" class="mt-6">mdi-arrow-expand</v-icon>
      </v-btn>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark class="mr-1" @click="$emit('close-dialog')">
        <v-icon size="35" class="mt-4">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      :class="
        $vuetify.breakpoint.mdAndUp
          ? 'py-15 px-15'
          : $vuetify.breakpoint.xs
          ? 'py-6 px-0'
          : 'py-6 px-3'
      "
    >
      <v-row>
        <v-col align="center" cols="12" md="8" class="pt-0">
          <v-carousel
            v-model="carousel"
            height="auto"
            :style="$vuetify.breakpoint.xs ? '' : 'max-width: 90%'"
            hide-delimiter-background
          >
            <div v-for="(item, i) in project.Bilderreihenfolge" :key="i">
              <!-- Bild -->
              <v-carousel-item
                v-if="item.Typ == 'Bild'"
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${project.path.replaceAll(
                  ' ',
                  '+'
                )}/${item.Pfad.replaceAll(' ', '+')}`"
              ></v-carousel-item>
              <v-carousel-item v-if="item.Typ == 'Video'">
                <video-player
                  class="mb-12"
                  src="https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/landingpage-video.mp4"
                  poster="https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/3.jpg"
                  :options="{
                    fluid: true,
                    muted: false,
                    language: 'de',
                    playbackRates: [1.0],
                    sources: [
                      {
                        type: 'video/mp4',
                        src: 'https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/Bibi/landingpage-video.mp4',
                      },
                    ],
                  }"
                />
              </v-carousel-item>
              <v-carousel-item v-if="item.Typ == 'YouTube'">
                <youtube
                  class="mb-12"
                  :video-id="item.ID"
                  ref="youtube"
                  :fitParent="true"
                  :resize="true"
                ></youtube>
              </v-carousel-item>
              <v-carousel-item v-if="item.Typ == 'TikTok'">
                <iframe
                  class="mb-12"
                  style="border-radius: 11px; margin-top: -1px"
                  height="756px"
                  width="323"
                  :src="`https://www.tiktok.com/embed/v2/${item.ID}`"
                  frameborder="0"
                ></iframe>
              </v-carousel-item>
            </div>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="4" class="order-first order-md-last">
          <div
            :class="
              $vuetify.breakpoint.smAndDown ? 'details-mobile' : 'details pl-10'
            "
          >
            <v-row>
              <v-col
                :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
                class="pa-0"
                cols="12"
                sm="4"
                md="12"
              >
                <h3>
                  {{ project.Kunde }}
                </h3>
                <p>
                  {{ project.Typ }}
                </p>
              </v-col>
              <v-col
                :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
                class="pa-0"
                cols="12"
                sm="4"
                md="12"
              >
                <h3>Pro&shy;duk&shy;tions&shy;dauer</h3>
                <p>
                  {{ project.Produktionsdauer }}
                </p>
              </v-col>
              <v-col
                :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
                class="pa-0"
                cols="12"
                sm="4"
                md="12"
              >
                <h3>Auf&shy;gaben&shy;be&shy;reich</h3>
                <p>
                  {{ project.Aufgabenbereich }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" fullscreen v-if="dialog_view_item.Pfad">
      <v-card @click="dialog = false" color="#2e2f3c">
        <v-img
          :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${project.path.replaceAll(
            ' ',
            '+'
          )}/${dialog_view_item.Pfad.replaceAll(' ', '+')}`"
          contain
          height="100vh"
        >
        </v-img>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  props: ["project"],
  data() {
    return {
      dialog: false,
      current_index: 0,
      carousel: 0,
      dialog_view_item: this.project.Bilderreihenfolge[0],
    };
  },
  computed: {
    isPicture() {
      return this.project.Bilderreihenfolge[this.carousel].Typ == "Bild";
    },
  },
  methods: {
    expand() {
      this.dialog = true;
      this.dialog_view_item = this.project.Bilderreihenfolge[this.carousel];
    },
  },
  components: {
    videoPlayer,
  },
};
</script>

<style>
.details h3 {
  color: white;
  font-size: 1.9rem;
}

.details p {
  color: white;
  font-size: 1.4rem;
}

.details-mobile h3 {
  color: white;
  font-size: 1.2rem;
}

.details-mobile p {
  color: white;
  font-size: 0.95rem;
}
</style>
