<template>
  <div class="menubar-spacing">
    <center>
      <h2>Sorry, diese Seite ist nicht Verfügbar!</h2>
      <p>
        Der Link, den Sie eingegeben haben ist nicht gültig oder die Seite wurde
        entfernt.
        <router-link
          style="text-decoration: underline; color: #315778 !important"
          to="/"
          >Zurück zur Startseite.</router-link
        >
      </p>
    </center>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
