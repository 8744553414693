import axios from "axios";

const s3 = axios.create({
    baseURL: "https://11sevendome-website.s3.eu-central-1.amazonaws.com",
});

export function loadJsonCustomers() {
    return s3.get("/customers/customers.json");
}
export function loadJsonLanding() {
    return s3.get("/landing/landing.json");
}
export function loadJsonPortfolio() {
    return s3.get("/projects/portfolio.json");
}
export function loadJsonProject(projectName) {
    return s3.get("/projects/" + String(projectName).replaceAll(' ', '+') + "/project.json");
}
