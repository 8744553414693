<template>
  <v-footer padless id="footer">
    <v-card flat tile class="pt-3 text-center" color="#315778" width="100%">
      <v-card-text class="white--text pt-0">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="3"
            md="2"
            style="align-self: center"
            :class="$vuetify.breakpoint.mdAndDown ? 'pb-0' : ''"
          >
            <router-link to="/kontakt"><h3>ABOUT</h3></router-link>
            <router-link to="/impressum"><h3>IMPRESSUM</h3></router-link>
            <router-link to="/datenschutz"><h3>DATENSCHUTZ</h3></router-link>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="5"
            style="align-self: center"
            class="pt-6 order-first order-sm-0 px-0"
          >
            <router-link to="/"
              ><h1
                :style="$vuetify.breakpoint.mdAndDown ? 'font-size: 30pt' : ''"
              >
                11SEVENDOME
              </h1></router-link
            >
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            style="align-self: center"
            :class="$vuetify.breakpoint.mdAndDown ? 'pt-0' : ''"
          >
            <router-link to="/shop"><h3>SHOP</h3></router-link>
            <router-link to="/portfolio"><h3>PORTFOLIO</h3></router-link>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text py-2">
        © Copyright 2022 11SEVENDOME
        <span class="spacer">|</span>
        11sevendome@gmail.com
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#footer h3 {
  font-weight: 400;
}

#footer h1 {
  font-weight: 400;
  font-size: 40pt;
}
@media screen and (max-width: 427px) {
  .spacer {
    color: rgba(0, 0, 0, 0); //make transparent trick
  }
}
</style>
