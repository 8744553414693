<template>
  <div class="menubar-spacing">
    <center>
      <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 30pt' : ''">Datenschutz</h2>
    </center>
    <v-container>
      <v-row>
        <v-col>
          <p>
            <b>Verantwortlicher für die Datenverarbeitung ist:</b> <br />
            Dominique Krentscher<br />
            Werdersche Dorfstraße 21<br />
            15345 Rehfelde<br />
            Deutschland<br />
            11sevendome@gmail.com
          </p>
          <p>
            Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz
            Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren
            wir Sie ausführlich über den Umgang mit Ihren Daten.
          </p>
          <ol>
            <li>
              <b>Zugriffsdaten und Hosting</b>
              <p>
                Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer
                Person zu machen. Bei jedem Aufruf einer Webseite speichert der
                Webserver lediglich automatisch ein sogenanntes Server-Logfile,
                das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse,
                Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den
                anfragenden Provider (Zugriffsdaten) enthält und den Abruf
                dokumentiert.
              </p>
              <p>
                Diese Zugriffsdaten werden ausschließlich zum Zwecke der
                Sicherstellung eines störungsfreien Betriebs der Seite sowie der
                Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art.
                6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer
                Interessensabwägung überwiegenden berechtigten Interessen an
                einer korrekten Darstellung unseres Angebots. Alle Zugriffsdaten
                werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs
                gelöscht.
              </p>

              <b>Hostingdienstleistungen durch einen Drittanbieter</b>
              <p>
                Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein
                Drittanbieter für uns die Dienste zum Hosting und zur
                Darstellung der Webseite. Alle Daten, die im Rahmen der Nutzung
                dieser Webseite oder in dafür vorgesehenen Formularen im
                Onlineshop wie folgend beschrieben erhoben werden, werden auf
                seinen Servern verarbeitet. Eine Verarbeitung auf anderen
                Servern findet nur in dem hier erläuterten Rahmen statt.
              </p>
              <p>
                Dieser Dienstleister sitzt innerhalb eines Landes der
                Europäischen Union oder des Europäischen Wirtschaftsraums.
              </p>
            </li>
            <li>
              <b
                >Datenerhebung und -verwendung zur Vertragsabwicklung,
                Kontaktaufnahme</b
              >
              <p>
                Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen
                Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B.
                per Kontaktformular oder E-Mail) mitteilen. Pflichtfelder werden
                als solche gekennzeichnet, da wir in diesen Fällen die Daten
                zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer
                Kontaktaufnahme benötigen und Sie ohne deren Angabe die
                Bestellung nicht abschließen, bzw. die Kontaktaufnahme nicht
                versenden können. Welche Daten erhoben werden, ist aus den
                jeweiligen Eingabeformularen ersichtlich.
              </p>
              <p>
                Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs.
                1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer
                Anfragen. Nach vollständiger Abwicklung des Vertrages werden
                Ihre Daten für die weitere Verarbeitung eingeschränkt und nach
                Ablauf etwaiger steuer- und handelsrechtlichen
                Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in
                eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns
                eine darüber hinausgehende Datenverwendung vorbehalten, die
                gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung
                informieren.
              </p>
            </li>
            <li>
              <b>Datenweitergabe</b>
              <p>
                Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO
                geben wir Ihre Daten an das mit der Lieferung beauftragte
                Versandunternehmen weiter, soweit dies zur Lieferung bestellter
                Waren erforderlich ist. Je nach dem, welchen
                Zahlungsdienstleister Sie im Bestellprozess auswählen, geben wir
                zur Abwicklung von Zahlungen die hierfür erhobenen Zahlungsdaten
                an das mit der Zahlung beauftragte Kreditinstitut und ggf. von
                uns beauftragte Zahlungsdienstleister weiter bzw. an den
                ausgewählten Zahlungsdienst. Zum Teil erheben die ausgewählten
                Zahlungsdienstleister diese Daten auch selbst, soweit Sie dort
                ein Konto anlegen. In diesem Fall müssen Sie sich im
                Bestellprozess mit Ihren Zugangsdaten bei dem
                Zahlungsdienstleister anmelden. Es gilt insoweit die
                Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
              </p>
              <p>
                Zur Bestell- und Vertragsabwicklung setzen wir zudem ein
                externes Warenwirtschaftssystem ein. Die insoweit stattfindende
                Datenweitergabe bzw. Verarbeitung basiert auf einer
                Auftragsverarbeitung. Wir setzen einen Zahlungsdienstleister
                ein, der seinen Sitz in einem Staat außerhalb der Europäischen
                Union hat. Die Übermittlung personenbezogener Daten an dieses
                Unternehmen erfolgt lediglich im Rahmen der Notwendigkeit zur
                Vertragserfüllung.
              </p>
            </li>
            <li>
              <b>Cookies</b>
              <p>
                Um den Besuch unserer Webseite attraktiv zu gestalten und die
                Nutzung bestimmter Funktionen zu ermöglichen, um passende
                Produkte anzuzeigen oder zur Marktforschung verwenden wir auf
                verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung
                unserer im Rahmen einer Interessensabwägung überwiegenden
                berechtigten Interessen an einer optimierten Darstellung unseres
                Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
              </p>
              <p>
                Cookies sind kleine Textdateien, die automatisch auf Ihrem
                Endgerät gespeichert werden. Einige der von uns verwendeten
                Cookies werden nach Ende der Browser-Sitzung, also nach
                Schließen Ihres Browsers, wieder gelöscht (sog.
                Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät
                und ermöglichen uns, Ihren Browser beim nächsten Besuch
                wiederzuerkennen (persistente Cookies). Die Dauer der
                Speicherung können Sie der Übersicht in den Cookie-Einstellungen
                Ihres Webbrowsers entnehmen. Sie können Ihren Browser so
                einstellen, dass Sie über das Setzen von Cookies informiert
                werden und einzeln über deren Annahme entscheiden oder die
                Annahme von Cookies für bestimmte Fälle oder generell
                ausschließen. Bei der Nichtannahme von Cookies kann die
                Funktionalität unserer Webseite eingeschränkt sein. Jeder
                Browser unterscheidet sich in der Art, wie er die
                Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes
                Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre
                Cookie-Einstellungen ändern können. Diese finden Sie für die
                jeweiligen Browser unter den folgenden Links:
              </p>
              <p>
                Microsoft Edge<sup>TM</sup> Safari<sup>TM</sup> Chrome<sup
                  >TM</sup
                >
                Firefox<sup>TM</sup> Opera<sup>TM</sup>
              </p>
            </li>
            <li>
              <b>Online-Marketing</b>
              <p>
                <b>Google Fonts</b><br />
                Auf dieser Webseite ist der Script Code „Google Fonts“
                eingebunden. Google Fonts ist ein Angebot der Google Ireland
                Limited, einer nach irischem Recht eingetragenen und betriebenen
                Gesellschaft mit Sitz in Gordon House, Barrow Street, Dublin 4,
                Irland. (www.google.de). Dies dient der Wahrung unserer im
                Rahmen einer Interessensabwägung überwiegenden berechtigten
                Interessen an einer einheitlichen Darstellung der Inhalte auf
                unserer Webseite gemäß Art. 6 Abs. 1 lit. f DSGVO. In diesem
                Rahmen wird eine Verbindung zwischen dem von Ihnen verwendeten
                Browser und den Servern von Google hergestellt. Hierdurch
                erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse
                unsere Webseite aufgerufen wurde.
              </p>
              <p>
                Soweit Informationen auf Server von Google in den USA übertragen
                und dort gespeichert werden, ist die amerikanische Gesellschaft
                Google LLC unter dem EU-US-Privacy Shield zertifiziert. Ein
                aktuelles Zertifikat kann hier eingesehen werden. Aufgrund
                dieses Abkommens zwischen den USA und der Europäischen
                Kommission hat letztere für unter dem Privacy Shield
                zertifizierte Unternehmen ein angemessenes Datenschutzniveau
                festgestellt. Weitergehende Informationen über die
                Datenverarbeitung durch Google finden Sie in den
                Datenschutzhinweisen von Google.
              </p>
            </li>
            <li>
              <b>Social Media</b>
              <p>
                <b>Unsere Onlinepräsenz auf Youtube, Instagram</b><br />
                Unsere Präsenz auf sozialen Netzwerken und Plattformen dient
                einer besseren, aktiven Kommunikation mit unseren Kunden und
                Interessenten. Wir informieren dort über unsere Produkte und
                laufende Sonderaktionen. Bei dem Besuch unserer Onlinepräsenzen
                in sozialen Medien können Ihre Daten für Marktforschungs- und
                Werbezwecke automatisch erhoben und gespeichert werden. Aus
                diesen Daten werden unter Verwendung von Pseudonymen sog.
                Nutzungsprofile erstellt. Diese können verwendet werden, um z.B.
                Werbeanzeigen innerhalb und außerhalb der Plattformen zu
                schalten, die mutmaßlich Ihren Interessen entsprechen. Zu diesem
                Zweck werden im Regelfall Cookies auf Ihrem Endgerät eingesetzt.
                In diesen Cookies werden das Besucherverhalten und die
                Interessen der Nutzer gespeichert. Dies dient gem. Art. 6 Abs. 1
                lit. f. DSGVO der Wahrung unserer im Rahmen einer
                Interessensabwägung überwiegenden berechtigten Interessen an
                einer optimierten Darstellung unseres Angebots und effektiver
                Kommunikation mit den Kunden und Interessenten. Falls Sie von
                den jeweiligen Social-Media Plattformbetreibern um eine
                Einwilligung (Einverständnis) in die Datenverarbeitung gebeten
                werden, z.B. mit Hilfe einer Checkbox, ist die Rechtsgrundlage
                der Datenverarbeitung Art. 6 Abs. 1 lit. a DSGVO. Soweit die
                vorgenannten Social-Media Plattformen ihren Hauptsitz in den USA
                haben, gilt Folgendes: Für die USA liegt ein
                Angemessenheitsbeschluss der Europäischen Kommission vor. Dieser
                geht zurück auf den EU-US Privacy Shield. Ein aktuelles
                Zertifikat für das jeweilige Unternehmen kann hier eingesehen
                werden. Die detaillierten Informationen zur Verarbeitung und
                Nutzung der Daten durch die Anbieter auf deren Seiten sowie eine
                Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und
                Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre,
                insbesondere Widerspruchsmöglichkeiten (Opt-Out), entnehmen Sie
                bitte den unten verlinkten Datenschutzhinweisen der Anbieter.
                Sollten Sie diesbezüglich dennoch Hilfe benötigen, können Sie
                sich an uns wenden.
              </p>
              <p>
                Google / Youtube / Instagram <br />
                Widerspruchsmöglichkeit (Opt-Out): Google / Youtube / Instagram
              </p>
            </li>
            <li>
            <b>Kontaktmöglichkeiten und Ihre Rechte</b>
            <p>
              Als Betroffener haben Sie folgende Rechte:
              <ul>
                <li>
                  gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang
      Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu
      verlangen
                </li>
                <li>
                  gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger
      oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
      Daten zu verlangen;
                </li>
                <li>
                  gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten
      personenbezogenen Daten zu verlangen, soweit nicht die weitere
      Verarbeitung: zur Erfüllung einer rechtlichen Verpflichtung; aus Gründen des
      öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen erforderlich ist;
                </li>
                <li>
      gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
      von Ihnen bestritten wird; die Verarbeitung unrechtmäßig ist, Sie aber deren
      Löschung ablehnen; wir die Daten nicht mehr benötigen, Sie diese jedoch
      zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
      benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
      Verarbeitung eingelegt haben;
                </li>
                <li>
      gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie
      uns bereitgestellt haben, in einem strukturierten, gängigen und
      maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
      anderen Verantwortlichen zu verlangen;
                </li>
                <li>
      gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu
      beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
      Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
      Unternehmenssitzes wenden.
                </li>
              </ul>
            </p>
          </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  <br>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
