<template>
  <v-app-bar app color="transparent" absolute dark elevation="0" id="menu-bar">
    <v-container>
      <v-row>
        <v-col cols="12" style="text-align: center">
          <router-link to="/">
            <h1
              class="pt-10 pb-0"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'font-size: 30pt'
                  : 'font-size: 40pt'
              "
            >
              11SEVENDOME
            </h1></router-link
          >
        </v-col>
      </v-row>
      <v-row class="mt-0" justify="center">
        <v-col class="py-0" style="text-align: center">
          <router-link to="/portfolio"
            ><span
              :class="
                $vuetify.breakpoint.smAndDown ? 'mr-2' : 'menu-item text-h6'
              "
              >PORTFOLIO</span
            >
          </router-link>
          <router-link to="/"
            ><span
              @click="goto('#services')"
              :class="
                $vuetify.breakpoint.smAndDown ? 'mr-2' : 'menu-item text-h6'
              "
              >SERVICES</span
            >
          </router-link>
          <router-link to="/"
            ><span
              @click="goto('#shop')"
              :class="
                $vuetify.breakpoint.smAndDown ? 'mr-2' : 'menu-item text-h6'
              "
              >SHOP</span
            >
          </router-link>
          <router-link to="/kontakt"
            ><span
              :class="$vuetify.breakpoint.smAndDown ? '' : 'menu-item text-h6'"
              >KONTAKT</span
            ></router-link
          >
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    goto(target) {
      let options = {
        duration: 1000,
        offset: -60,
        easing: "easeInOutQuad",
      };
      setTimeout(() => {
        this.$vuetify.goTo(target, options);
      }, 500);
    },
  },
};
</script>

<style>
#menu-bar .menu-item {
  margin-right: 40px;
  font-family: Josefin Sans, sans-serif !important;
}
</style>
