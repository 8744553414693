<template>
  <v-app>
    <MenuBar />

    <v-main style="background-color: #2e2f3c; padding-top: 0px">
      <router-view :key="$route.name + ($route.params.id || '')"></router-view>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import MenuBar from "./components/MenuBar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    MenuBar,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Josefin Sans";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Josefin Sans"),
    url("/public/fonts/JosefinSans-VariableFont_wght.ttf") format("truetype");
}

html,
body {
  font-family: "Josefin Sans", sans-serif !important;
  overflow-x: hidden;
}

#app {
  font-family: "Josefin Sans", sans-serif !important;
  color: white;
}

.menubar-spacing {
  margin-top: 180px;
}

h1 {
  font-weight: 500;
  font-size: 40pt;
  color: white;
}

h2 {
  letter-spacing: 0px;
  font-size: 65px;
  font-weight: 900;
  color: white;
}

a {
  text-decoration: none;
  color: white !important;
}
</style>
