<template>
  <div id="Landing" v-if="landingJson">
    <!-- frontpage video -->
    <v-container fluid class="pa-0">
      <video
        id="video"
        :src="
          $vuetify.breakpoint.xs
            ? 'https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/landingpage-video-mobile.mp4'
            : 'https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/landingpage-video.mp4'
        "
        autoplay
        playsinline
        loop
        :muted="muted"
        class="front-video"
      ></video>
      <div id="social-media">
        <v-row>
          <v-col cols="3">
            <a href="https://www.instagram.com/11sevendome/">
              <v-img
                class="social-media-icon"
                :width="$vuetify.breakpoint.smAndDown ? 30 : 50"
                :src="imageInstagram"
                @mouseover="
                  imageInstagram = imageInstagram.replace(
                    'instagram',
                    'instagram-hover'
                  )
                "
                @mouseleave="
                  imageInstagram = imageInstagram.replace(
                    'instagram-hover',
                    'instagram'
                  )
                "
              ></v-img>
            </a>
          </v-col>
          <v-col cols="3">
            <a href="https://open.spotify.com/show/3CsVqoXDfLLjsYHm6ArIa7">
              <v-img
                class="social-media-icon"
                :width="$vuetify.breakpoint.smAndDown ? 30 : 50"
                :src="imageSpotify"
                @mouseover="
                  imageSpotify = imageSpotify.replace(
                    'spotify',
                    'spotify-hover'
                  )
                "
                @mouseleave="
                  imageSpotify = imageSpotify.replace(
                    'spotify-hover',
                    'spotify'
                  )
                "
              ></v-img>
            </a>
          </v-col>
          <v-col cols="3">
            <a href="https://www.tiktok.com/@11sevendome">
              <v-img
                class="social-media-icon"
                :width="$vuetify.breakpoint.smAndDown ? 30 : 50"
                :src="imageTiktok"
                @mouseover="
                  imageTiktok = imageTiktok.replace('tiktok', 'tiktok-hover')
                "
                @mouseleave="
                  imageTiktok = imageTiktok.replace('tiktok-hover', 'tiktok')
                "
              ></v-img
            ></a>
          </v-col>
          <v-col cols="3">
            <a href="https://www.youtube.com/c/11sevenDome">
              <v-img
                class="social-media-icon"
                :width="$vuetify.breakpoint.smAndDown ? 30 : 50"
                :src="imageYoutube"
                @mouseover="
                  imageYoutube = imageYoutube.replace(
                    'youtube',
                    'youtube-hover'
                  )
                "
                @mouseleave="
                  imageYoutube = imageYoutube.replace(
                    'youtube-hover',
                    'youtube'
                  )
                "
              ></v-img> </a
          ></v-col>
        </v-row>
      </div>
      <div id="muteButton">
        <v-icon
          color="white"
          large
          @click="muted = !muted"
          class="mute-button"
          :width="$vuetify.breakpoint.smAndDown ? 20 : 50"
          >{{ muted ? "mdi-volume-off" : "mdi-volume-high" }}</v-icon
        >
      </div>
    </v-container>
    <!-- Portfolio -->
    <v-container class="pa-0" v-if="projectlength == 6">
      <v-row class="pt-8">
        <v-col align="center">
          <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 45px' : ''">
            PORTFOLIO
          </h2>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.smAndDown ? 'px-6' : 'px-12'">
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[1])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[1].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[1].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[1].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[1].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[2])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[2].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[2].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[2].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[2].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[3])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[3].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[3].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[3].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[3].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
        <!-- Row 2 -->
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[4])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[4].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[4].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[4].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[4].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[5])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[5].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[5].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[5].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[5].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
        <v-col
          style="position: relative; cursor: pointer"
          @click="openDialog(portfolioPreview[6])"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <div>
              <v-img
                :src="`https://11sevendome-website.s3.eu-central-1.amazonaws.com/projects/${portfolioPreview[6].path.replaceAll(
                  ' ',
                  '+'
                )}/${portfolioPreview[6].Thumbnail.replaceAll(' ', '+')}`"
                :aspect-ratio="4 / 3"
                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)"
              >
              </v-img>
              <div
                class="white--text"
                :style="`position: absolute; bottom: ${
                  hover ? '12%' : '10%'
                }; left: 10%`"
              >
                <b class="mb-0">{{ portfolioPreview[6].Kunde }}</b>
                <p class="mb-0">{{ portfolioPreview[6].Typ }}</p>
              </div>
            </div>
          </v-hover>
        </v-col>
      </v-row>
      <!-- Button -->
      <v-row>
        <v-col align="center">
          <router-link to="/portfolio">
            <v-btn class="px-9" outlined rounded color="white" elevation="0"
              >Zeig mir mehr</v-btn
            >
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <!-- Services -->
    <v-container class="pa-0">
      <v-row class="pt-10">
        <v-col align="center">
          <h2
            id="services"
            :style="$vuetify.breakpoint.smAndDown ? 'font-size: 45px' : ''"
          >
            SERVICES
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="9"
          align="center"
          class="white--text text-left ml-3 pl-12"
          :style="
            'border-left: 10px solid white; ' +
            ($vuetify.breakpoint.smAndDown
              ? 'font-size: 20px;'
              : 'font-size: 30px;')
          "
        >
          <p>
            Durch Planung und Kozeptionierung setzen wir Dein <b>Werbevideo</b>,
            Deinen <b>Imagefilm</b> , Dein <b>Event</b> oder Deine
            <b>Reportage</b> in die Realität um.
          </p>
          <p class="mb-0">
            Aus Deiner Idee wird dann Mithilfe von Planung und Kozeptionierung
            das Projekt visuell überzeugend umgesetzt.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-8">
        <v-col
          cols="9"
          md="7"
          align="center"
          class="white--text text-left pl-0"
          style="font-size: 20px"
        >
          <v-img
            style="display: inline-block"
            width="22px"
            src="./assets/landing/triangleDownIcon.png"
          ></v-img>
          <h3
            class="mb-3 ml-10"
            :style="
              'display: inline-block; ' +
              ($vuetify.breakpoint.smAndDown
                ? 'font-size: 25px;'
                : 'font-size: 30px;')
            "
          >
            Wie läuft die Zusammenarbeit ab?
          </h3>
          <ol class="pl-16">
            <li class="mb-2">
              Für transparente und genaue Komunikation, nehmen wir über ein
              Video-Call die Projektinformationen/<wbr />Projektvorstellungen
              auf. Gemeinsam arbeiten wir ein kreatives Konzept aus.
            </li>
            <li class="mb-2">
              Mit unserem Team und starken Partnern setzen wir anspruchsvollste
              Filmproduktionen um. Kein Projekt ist zu groß.
            </li>
            <li class="mb-2">
              Unser Team realisiert kreative Edits, die eine Geschichte
              erzählen. Color Grading, Sound Design und Komposition gehört zu
              unseren Kompetenzen.
            </li>
          </ol>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
      </v-row>
    </v-container>
    <!-- Kunden -->
    <v-container class="pa-0">
      <v-row class="pt-4">
        <v-col align="center">
          <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 45px' : ''">
            KUNDEN
          </h2>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col
          align="center"
          cols="12"
          sm="6"
          md="4"
          v-for="index in Object.keys(landingJson.Kunden).length"
          :key="index"
        >
          <v-img
            contain
            height="150px"
            :src="
              'https://11sevendome-website.s3.eu-central-1.amazonaws.com/customers/' +
              landingJson.Kunden[index]
            "
          >
          </v-img>
        </v-col>
      </v-row>
      <!-- Button -->
      <v-row>
        <v-col align="center">
          <router-link to="/happy-customers">
            <v-btn class="px-9" outlined rounded color="white" elevation="0"
              >Zeig mir mehr</v-btn
            ></router-link
          >
        </v-col>
      </v-row>
    </v-container>
    <!-- Shop -->
    <v-container class="pa-0 mb-10">
      <v-row class="pt-12">
        <v-col align="center">
          <h2
            id="shop"
            :style="$vuetify.breakpoint.smAndDown ? 'font-size: 45px' : ''"
          >
            SHOP
          </h2>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="6" :align="$vuetify.breakpoint.xs ? 'center' : ''">
          <v-img
            height="100%"
            :width="$vuetify.breakpoint.xs ? '80%' : '100%'"
            src="https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/shop-picture-1.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="white--text">
          <div class="mx-4">
            <h3 style="font-size: 30px" class="mb-4">PRESETS</h3>
            <p style="font-size: 25px; font-weight: 100">
              Mithilfe von einfachen Klicks erreichts du einen unverkennbaren
              Look der leicht individualisierbar und an deinen eigenen Stil
              anpassbar ist. Egal ob Portraits, Automotiv, Reportage und
              Landschaftsfotografie.
            </p>
            <v-btn
              class="px-9 mt-2"
              outlined
              rounded
              color="white"
              elevation="0"
              >Kommt bald!</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col
          v-if="$vuetify.breakpoint.xs"
          cols="12"
          sm="6"
          :align="$vuetify.breakpoint.xs ? 'center' : ''"
        >
          <v-img
            height="100%"
            :width="$vuetify.breakpoint.xs ? '80%' : '100%'"
            src="https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/shop-picture-2.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="white--text">
          <div class="mx-4">
            <h3 style="font-size: 30px" class="mb-4">LUTs</h3>
            <p style="font-size: 25px; font-weight: 100">
              Egal ob du diese Looks als Base- Grading, in Kombination mit
              anderen Website 6 LUTs oder als Stand-Alone Lösung verwendest -
              Die Cinematic Kollektion wird deinem Footage einen besonderen und
              einzigartigen Look verleihen.
            </p>
            <v-btn
              class="px-9 mt-2"
              outlined
              rounded
              color="white"
              elevation="0"
              >Kommt bald!</v-btn
            >
          </div>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.xs"
          cols="12"
          sm="6"
          :align="$vuetify.breakpoint.xs ? 'center' : ''"
        >
          <v-img
            height="100%"
            :width="$vuetify.breakpoint.xs ? '80%' : '100%'"
            src="https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/shop-picture-2.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      width="90%"
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
    >
      <ProjectView :project="currentProject" @close-dialog="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import ProjectView from "@/components/ProjectView.vue";
import { loadJsonLanding, loadJsonProject } from "./aws.js";

export default {
  data() {
    return {
      imageMute: "",
      imageInstagram:
        "https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/social-media-icons/instagram.png",
      imageSpotify:
        "https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/social-media-icons/spotify.png",
      imageTiktok:
        "https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/social-media-icons/tiktok.png",
      imageYoutube:
        "https://11sevendome-website.s3.eu-central-1.amazonaws.com/landing/social-media-icons/youtube.png",
      muted: true,
      image: null,
      landingJson: null,
      portfolioPreview: {},
      projectlength: 0,
      dialog: false,
      currentProject: null,
    };
  },
  mounted() {
    this.image = require("./assets/landing/projects/test.jpg");
    loadJsonLanding()
      .then((res) => {
        this.landingJson = res.data;

        Object.keys(this.landingJson.Portfolio).forEach((id) => {
          loadJsonProject(this.landingJson.Portfolio[id])
            .then((response) => {
              // add project data to project list
              let project_data = response.data;
              project_data.path = this.landingJson.Portfolio[id];
              this.portfolioPreview[id] = project_data;
              this.projectlength++;
            })
            .catch((err) => console.log(err));
        });
      })

      .catch((err) => console.log(err));
  },
  methods: {
    openDialog(project) {
      this.dialog = true;
      this.currentProject = project;
    },
  },
  components: {
    ProjectView,
  },
};
</script>

<style>
#Landing .front-video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0px;
}

.full-height {
  height: 100vh;
}
#social-media {
  position: absolute;
  top: 100vh;
  margin-top: -75px;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline;
}
.social-media-icon {
  cursor: pointer;
}
#muteButton {
  position: absolute;
  top: 100vh;
  margin-top: -75px;
  right: 50px;
}
@media screen and (max-width: 500px) {
  #muteButton {
    top: 100vh;
    margin-top: -80px;
    right: 25px;
  }
}
</style>
