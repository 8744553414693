<template>
  <div class="menubar-spacing">
    <center>
      <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 30pt' : ''">
        KONTAKT
      </h2>
      <p>11sevendome@gmail.com</p>
    </center>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'"
          class="pr-6"
        >
          <v-img
            width="350px"
            src="https://11sevendome-website.s3.eu-central-1.amazonaws.com/about/about.jpg"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6">
          <b>Hauptberuf</b>
          <br />
          Videoeditor/Fotograf
          <br /><br />
          <b>Zahlreiche Erfahrung als: </b>
          <br />
          Kameramann/DOP, Lichtassistent, Cutter
          <br /><br />
          <h3>VITA</h3>
          <br />
          + 2021: Praktikum in der Medienabteilung bei der DBU – Digital
          Business University of Applied Scienes
          <br />
          + 2018: Ausbildung als Mediengestalter Bild und Ton 2018: Studium
          Maschinenbau (2 Semester)
          <br />
          + 2015 - 2017: Fachabitur mit Schwerpunkt Technik
          <br />
          + 2014 - 2016: Praktikum im 3D Labor des Instituts für Mathematik (TU
          Berlin)
          <br />
          + 2014: Freiwilliges Soziales Jahr im Bereich Altenpflege
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
