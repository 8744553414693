import Vue from "vue";
import Router from "vue-router";
import Landing from "@/Landing";
import Contact from "@/Contact";
import ErrorPage from "@/ErrorPage";
import Impressum from "@/Impressum";
import Privacy from "@/Privacy";
import Customers from "@/Customers";
import Portfolio from "@/Portfolio";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      component: Landing,
    },
    {
      path: "/happy-customers",
      name: "happy-customers",
      component: Customers,
    },
    {
      path: "/kontakt",
      name: "kontakt",
      component: Contact,
    },
    {
      path: "/impressum",
      name: "impressum",
      component: Impressum,
    },
    {
      path: "/datenschutz",
      name: "privacy",
      component: Privacy,
    },
    {
      path: "/portfolio",
      name: "portfolio",
      component: Portfolio,
    },
    {
      path: "*",
      name: "404-not-found",
      component: ErrorPage,
    },
  ],
});
